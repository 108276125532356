/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* Add application styles & imports to this file! */
@import '~jsoneditor/dist/jsoneditor.min.css';

.fixed-width {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}